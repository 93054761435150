import { createAction, props } from '@ngrx/store';
import { InfoCheckLicenseKey, OperatorDTOExtended } from '../../operators/operators.model';
import { HttpStatusCode } from '@angular/common/http';

export const clearState = createAction('[Auth] clear state');

export const login = createAction('[Auth/Api] login');
export const loginSuccess = createAction('[Auth/Api] login success', props<{ operator: OperatorDTOExtended }>());
export const loginFailure = createAction('[Auth/Api] login failure', props<{ code: HttpStatusCode, reason: InfoCheckLicenseKey }>());

export const refreshLoggedOperator = createAction('[Auth/Api] refresh logged operator');

export const logout = createAction('[Auth] logout');
